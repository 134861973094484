<template>
  <div class="empty" :class="currentClass">
    <atomic-image class="empty__image" v-if="image" :src="image" />
    <atomic-icon-colored v-else-if="currentIcon" :id="currentIcon" />
    <div class="title">{{ title }}</div>
    <div class="sub-title" v-html="marked.parse(subTitle)" />
  </div>
</template>

<script setup lang="ts">
  import { marked } from 'marked';

  interface IProps {
    variant?: 'transactions'|'notification'|'bonuses'|'bets-history'|'not-found';
    image?: string;
    title: string;
    subTitle?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    title: '',
    subTitle: ''
  });

  const currentClass = computed(() =>
    props.variant ? `variant-${props.variant}` : undefined
  );

  const currentIcon = computed(() =>
    props.variant ? `empty-${props.variant}` : undefined
  );
</script>

<style src="~/assets/styles/components/atomic/empty.scss" lang="scss" />
